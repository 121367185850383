/**
 * Maps an array to an array of objects with elements and keys for React components,
 * avoiding the use of index as a key to make SonarQube happy.
 *
 * @template T - The type of elements in the input array.
 * @param {Array<T>} array - The input array to be mapped.
 * @returns {Array<{ el: T, key: number }>} An array of objects containing the original elements and unique keys.
 */

export function unnecessarySonarArrayMapper<T>(
  array: T[]
): Array<{ el: T; key: number }> {
  return array.map((el, i) => ({
    el,
    key: i,
  }));
}
