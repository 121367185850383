import React, { useEffect, useState } from 'react';
import {
  Box,
  Center,
  Container,
  Flex,
  Heading,
  HStack,
  Image,
  SimpleGrid,
  StackDivider,
  Text,
} from '@chakra-ui/react';

import NextImage from 'next/image';

import homeImage from '@/assets/purchase-mobile.png';

import Section from '@/components/common/Section';
import Layout from '@/components/common/Layout';

function HomePage() {
  const [coverNavBorder, setCoverNavBorder] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setCoverNavBorder(window.scrollY === 0);
    };

    handleScroll();

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <Layout>
      <Box
        as="section"
        bg="DNGBlue"
        color="white"
        marginTop={{
          base: 0,
          md: '-2px',
        }}
        py={{
          base: 12,
          md: 'unset',
        }}
        position="relative"
        zIndex={{
          base: 1,
          md: coverNavBorder ? 999 : 1,
        }}
      >
        <Container
          display="flex"
          flexDir={{
            base: 'column',
            md: 'row',
          }}
        >
          <Flex alignItems="center" flex={1}>
            <Box
              py={{
                base: 0,
                md: 16,
              }}
            >
              <Heading
                fontSize={{
                  base: '2xl',
                  md: '3xl',
                  lg: '5xl',
                }}
                pr={4}
              >
                Discover® Secure Remote Commerce
                <br />
                Developer Console
              </Heading>
              <Box mt={12} mb={4}>
                <Text
                  fontSize={{
                    base: '2xl',
                    md: '4xl',
                  }}
                  fontWeight={600}
                >
                  Get started
                </Text>
                <Text fontSize="lg">
                  Start testing, get building and keep innovating with Discover
                  APIs
                </Text>
              </Box>
            </Box>
          </Flex>
          <Box
            textAlign="right"
            position={{
              base: 'static',
              md: 'absolute',
            }}
            top={0}
            bottom={0}
            right={0}
            h={{
              base: '360px',
              md: '400px',
            }}
            mr={{
              base: -4,
              md: 0,
            }}
          >
            <Box
              height="100%"
              my={1}
              filter="drop-shadow(-4px 3px 3px rgba(0,0,0,0.3))"
              display="inline-block"
            >
              <NextImage
                src={homeImage}
                alt="mobile purchase"
                width={0}
                height={0}
                style={{ width: 'auto', height: '100%' }}
              />
            </Box>
          </Box>
        </Container>
      </Box>
      <Section
        my={{
          base: 4,
          md: 12,
        }}
      >
        <SimpleGrid columns={{ base: 1, lg: 2 }}>
          <Box>
            <Heading mb={8}>
              What is Discover Secure
              <br />
              Remote Commerce?
            </Heading>
            <Text mb={8} fontSize="xl">
              Discover Secure Remote Commerce (SRC) is the Discover® Global
              Network implementation of EMVCo&apos;s globally interoperable
              EMV® Secure Remote Commerce Specifications—enabling an online
              checkout experience that is simple, streamlined and more secure.
            </Text>
            <Text fontSize="sm">
              <Image
                src="/images/SRC_logo.svg"
                alt="src logo"
                h="12px"
                display="inline-block"
                mb="2px"
              />
              is a trademark owned by and used with permission of EMVCo, LLC.
            </Text>
          </Box>
          <Center>
            <HStack
              divider={<StackDivider />}
              spacing={4}
              mt={{
                base: 12,
                lg: 0,
              }}
            >
              {
                <Image
                  src="/images/SRC_logo.svg"
                  alt="src logo"
                  h={{
                    base: '50px',
                    sm: '60px',
                    md: '80px',
                  }}
                />
              }
              <Image
                src="/images/dng_logo.svg"
                alt="src logo"
                h={{
                  base: '50px',
                  sm: '60px',
                  md: '80px',
                }}
              />
            </HStack>
          </Center>
        </SimpleGrid>
      </Section>
    </Layout>
  );
}

export const getStaticProps = async () => {
  return {
    props: {},
  };
};

export default HomePage;
