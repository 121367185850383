import React from 'react';
import { Box, Button, VStack } from '@chakra-ui/react';
import { useRouter } from 'next/router';
import { APP_ROUTES } from '@/utils/routes';

export const SIDEBAR_NAV_ITEMS = [
  { to: APP_ROUTES.DASHBOARD, label: 'Dashboard' },
  { to: APP_ROUTES.RESOURCES, label: 'Resources' },
  { to: APP_ROUTES.TEST_API, label: 'Test APIs' },
];

const NAVBAR_HEIGHT = '74px';
const ASIDE_PADDING_TOP = '2rem';

type NavButtonProps = {
  children: React.ReactNode;
  to: string;
};

const NavButton = ({ children, to }: NavButtonProps) => {
  const { push, pathname } = useRouter();
  const isActive = pathname === to;
  return (
    <Button
      variant="ghost"
      textAlign="left"
      width="100%"
      color="white"
      size="lg"
      borderStyle="solid"
      borderWidth={2}
      borderColor={isActive ? 'white' : 'transparent'}
      _hover={{
        color: 'midnight',
        background: 'gray.100',
      }}
      onClick={() => push(to)}
    >
      {children}
    </Button>
  );
};

const Sidebar = () => {
  return (
    <Box
      as="aside"
      minW={{
        md: '240px',
        lg: '320px',
      }}
      px={4}
      py={8}
      borderStyle="solid"
      background="DNGBlue"
      color="white"
      display={{
        base: 'none',
        md: 'block',
      }}
    >
      <VStack
        position="sticky"
        top={`calc(${NAVBAR_HEIGHT} + ${ASIDE_PADDING_TOP})`}
      >
        {SIDEBAR_NAV_ITEMS.map(({ to, label }, id) => (
          <NavButton key={`sidebar-item-${id}`} to={to}>
            {label}
          </NavButton>
        ))}
      </VStack>
    </Box>
  );
};

export default Sidebar;
