import React from 'react';
import { Box, BoxProps, Container } from '@chakra-ui/react';

type SectionProps = BoxProps & {
  withBg?: boolean;
  dashboard?: boolean;
};

const Section = ({ children, withBg, dashboard, ...props }: SectionProps) => {
  return (
    <Box
      as="section"
      {...{
        pt: 8,
        pb: 12,
        ...(withBg && {
          px: {
            base: 0,
            md: 12,
          },
          bg: 'DNGBlue',
          color: 'white',
        }),
        ...(dashboard && {
          py: 8,
          px: {
            base: 0,
            md: 8,
            xl: 12,
          },
        }),
        ...props,
      }}
    >
      <Container position="relative">{children}</Container>
    </Box>
  );
};

export default Section;
