import React from 'react';
import { Box, Flex } from '@chakra-ui/react';
import { NextSeo } from 'next-seo';
import Header from '@/components/common/Header';
import Footer from '@/components/common/Footer';
import Sidebar from './Sidebar';

interface LayoutProps {
  children: React.ReactNode;
  isDashboard?: boolean;
  title?: string;
}

const Layout = ({ children, isDashboard = false, title }: LayoutProps) => {
  return (
    <Box minH="100vh" display="flex" flexDirection="column">
      <NextSeo title={title} />
      <Header isDashboard={isDashboard} />
      <Flex flex={1} display="flex">
        {isDashboard && <Sidebar />}
        <Box as="main" w="full" overflow="hidden">
          {children}
        </Box>
      </Flex>
      <Footer />
    </Box>
  );
};

export default Layout;
