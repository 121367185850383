import React, { useMemo } from 'react';
import { observer } from 'mobx-react';
import {
  As,
  Box,
  Button,
  ButtonProps,
  Container,
  Flex,
  IconButton,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Stack,
} from '@chakra-ui/react';
import Link from 'next/link';
import { HamburgerIcon } from '@chakra-ui/icons';
import NextImage from 'next/image';
import { APP_ROUTES } from '@/utils/routes';
import { useStores } from '@/hooks/useStores';
import logo from '@/assets/dng_logo.svg';
import { SIDEBAR_NAV_ITEMS } from '@/components/common/Sidebar';
import { unnecessarySonarArrayMapper } from '@/utils/unnecessarySonarArrayMapper';

type Item = ButtonProps & {
  label?: string;
  to?: string;
  onClick?: () => void;
  divider?: boolean;
};

type DesktopMenuProps = {
  items: Item[];
};

const DesktopMenu = ({ items }: DesktopMenuProps) => {
  const content = unnecessarySonarArrayMapper(items).map(
    ({ el: { to, onClick, label, ...props }, key }) => {
      const buttonProps: { as?: As; onClick?: () => void } = to
        ? { as: 'a' }
        : { onClick };

      const buttonBody = (
        <Button
          key={`nav-item-${key}`}
          minW="100px"
          {...buttonProps}
          {...props}
        >
          {label}
        </Button>
      );

      if (to) {
        return (
          <Link key={`nav-item-${key}`} href={to} legacyBehavior passHref>
            {buttonBody}
          </Link>
        );
      }

      return buttonBody;
    }
  );

  return <>{content}</>;
};

type MobileMenuProps = {
  items: Item[];
};

const MobileMenu = ({ items }: MobileMenuProps) => (
  <Box display={{ base: 'block', md: 'none' }}>
    <Menu>
      <MenuButton
        as={IconButton}
        aria-label="Menu"
        icon={<HamburgerIcon />}
        variant="outline"
      />
      <MenuList>
        {unnecessarySonarArrayMapper(items).map(
          ({ el: { divider, to, onClick, label }, key }) => {
            if (divider) {
              return <MenuDivider key={`nav-divider-${key}`} />;
            }
            const itemProps = to ? { href: to, as: Link } : { onClick };

            return (
              <MenuItem
                key={`nav-item-${key}`}
                {...itemProps}
                py={3}
                fontWeight="medium"
              >
                {label}
              </MenuItem>
            );
          }
        )}
      </MenuList>
    </Menu>
  </Box>
);

interface HeaderProps {
  isDashboard?: boolean;
}

function Header({ isDashboard }: HeaderProps) {
  const {
    authStore: {
      user: { isAuthenticated },
      handleLogout,
    },
  } = useStores();

  const PUBLIC_NAV = useMemo(
    () => [
      ...(isAuthenticated
        ? [
            {
              to: APP_ROUTES.DASHBOARD,
              label: 'Developer Console',
              variant: 'outline',
              colorScheme: 'gray',
            },
            {
              onClick: handleLogout,
              label: 'Logout',
            },
          ]
        : [
            {
              to: APP_ROUTES.SIGN_IN,
              label: 'Sign In',
              variant: 'outline',
            },
            {
              label: 'Request an Account',
              to: APP_ROUTES.SIGN_UP,
            },
          ]),
    ],
    [isAuthenticated, handleLogout]
  );

  const DASHBOARD_DESKTOP_NAV = useMemo(
    () => [
      {
        to: APP_ROUTES.MY_PROFILE,
        variant: 'ghost',
        label: 'Profile',
      },
      {
        onClick: handleLogout,
        label: 'Logout',
      },
    ],
    [handleLogout]
  );

  const DASHBOARD_MOBILE_NAV = useMemo(
    () => [
      ...SIDEBAR_NAV_ITEMS,
      { to: APP_ROUTES.MY_PROFILE, label: 'My Profile' },
      {
        divider: true,
      },
      {
        onClick: handleLogout,
        label: 'Logout',
      },
    ],
    [handleLogout]
  );

  return (
    <Box
      as="header"
      borderColor={'DNGBlue'}
      borderBottomWidth={2}
      position="sticky"
      top={0}
      zIndex={99}
      background="white"
    >
      <Container>
        <Flex minH={'60px'} py={4} align={'center'}>
          <Flex flex={{ base: 1 }}>
            <Link href={APP_ROUTES.HOME}>
              <NextImage height={40} src={logo} alt="logo" />
            </Link>
          </Flex>

          <Box>
            <Stack
              flex={1}
              justify={'flex-end'}
              alignItems={'center'}
              direction={'row'}
              spacing={6}
              display={{ base: 'none', md: 'flex' }}
            >
              <DesktopMenu
                items={isDashboard ? DASHBOARD_DESKTOP_NAV : PUBLIC_NAV}
              />
            </Stack>
            <MobileMenu
              items={isDashboard ? DASHBOARD_MOBILE_NAV : PUBLIC_NAV}
            />
          </Box>
        </Flex>
      </Container>
    </Box>
  );
}

export default observer(Header);
