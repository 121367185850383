import React from 'react';
import { Box, Container, Flex, HStack, Link } from '@chakra-ui/react';
import NextImage from 'next/image';
import logo from '@/assets/dng_logo_white.svg';

const Footer = () => {
  return (
    <Box as="footer" color="white" bg="midnight" py={8} px={4}>
      <Container>
        <Flex>
          <Box mr={8}>
            <NextImage src={logo} alt="logo" height={40} />
          </Box>

          <HStack spacing={4}>
            <Link
              href={process.env.NEXT_PUBLIC_TERMS_URL}
              target="_blank"
              rel="noopener noreferrer"
            >
              Terms of use
            </Link>
            <Link
              href={process.env.NEXT_PUBLIC_POLICY_URL}
              target="_blank"
              rel="noopener noreferrer"
            >
              Privacy Policy
            </Link>
          </HStack>
        </Flex>
      </Container>
    </Box>
  );
};

export default Footer;
